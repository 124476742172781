import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ICaseQueryEdge } from '../../../../queries/cases/types';
import { usePathPrefix } from '../../../../constants/hooks';
import { TLink } from '../../../../i18n/TLink';
import { IconComponent } from '../../../common/Icon';
import { AndroidIcon } from '../../../icons/AndroidIcon';
import { AppleIcon } from '../../../icons/AppleIcon';
import { WebIcon } from '../../../icons/WebIcon';
import { changePath } from '../../../../utils/hooks';
import { isTooDark } from '../../../../utils/color';

const platformIcons: Record<string, FC<any>> = {
  android: AndroidIcon,
  ios: AppleIcon,
  web: WebIcon,
};

interface IProps {
  item: ICaseQueryEdge['node'];
  appearance?: string;
  from?: string;
  className?: string;
  deviceClassName?: string;
  isReversed?: boolean;
}

const device: Record<string, string> = {
  android: '/devices/android',
  androidTablet: '/devices/androidTablet',
  iphone: '/devices/iphone',
  'iphone-xv': '/devices/iphone-xv',
  ipad: '/devices/ipad',
  web: '/devices/web',
};

const deviceScreen: Record<string, Record<string, number>> = {
  'iphone-portrait': {
    x: 21,
    y: 22,
    screenHeight: 310,
  },
  'iphone-xv-portrait': {
    x: 9,
    y: 8,
    screenHeight: 325,
  },
  'android-portrait': {
    x: 13,
    y: 50,
    screenHeight: 330,
  },
  'web-portrait': {
    x: 40,
    y: 25,
  },
  'iphone-landscape': {
    x: 20,
    y: 20,
    screenHeight: 220,
  },
  'androidTablet-portrait': {
    x: 34,
    y: 56,
    screenHeight: 390,
  },
};

const CaseCell: FC<IProps> = ({
  item,
  appearance = '',
  from,
  className,
  deviceClassName,
}) => {
  const {
    frontmatter: { previewImage },
  } = item;

  const deviceSettings = previewImage.deviceSettings || {};
  const prefix = usePathPrefix();
  const link = changePath(item.fileAbsolutePath, '/cases/projects/');

  const isColorDark = isTooDark(previewImage.background);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';

  const { platforms } = previewImage;
  const background = previewImage.background?.startsWith('#')
    ? previewImage.background
    : `url('${previewImage.background}')`;

  return (
    <div
      className={classNames(styles.cell, styles[appearance], className, {
        [styles.rotated]: deviceSettings.orientation === 'landscape',
      })}
      style={{
        background,
      }}
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <h3 style={{ color: fontColor }}>{previewImage.title}</h3>
            <p style={{ color: fontColor }}>{previewImage.year}</p>
          </div>

          <div className={styles.header__icons}>
            {platforms &&
              platforms.map((el, i) => (
                <span key={el} className={styles.header__iconsItem}>
                  <IconComponent
                    name={el}
                    icons={platformIcons}
                    iconProps={{ color: fontColor, width: 24, height: 24 }}
                  />
                </span>
              ))}
          </div>
        </div>

        <div
          className={classNames(styles.device, deviceClassName, {
            [styles.rotated]: deviceSettings.orientation === 'landscape',
            [styles.mobile]:
              deviceSettings.platform === 'iphone' ||
              deviceSettings.platform === 'android',
            [styles.iphoneXv]: deviceSettings.platform === 'iphone-xv',
            [styles.tabletOrWeb]:
              deviceSettings.platform === 'androidTablet' ||
              deviceSettings.platform === 'ipad' ||
              deviceSettings.platform === 'web',
          })}
        >
          <svg className={styles.device__frame}>
            <svg
              width="100%"
              height="100%"
              preserveAspectRatio="none"
              className={styles.device__screen}
            >
              <image
                height={
                  deviceScreen[
                    `${deviceSettings.platform}-${deviceSettings.orientation}`
                  ]?.screenHeight
                }
                x={
                  deviceScreen[
                    `${deviceSettings.platform}-${deviceSettings.orientation}`
                  ]?.x
                }
                y={
                  deviceScreen[
                    `${deviceSettings.platform}-${deviceSettings.orientation}`
                  ]?.y
                }
                href={previewImage.photo}
              />
            </svg>
            <image
              href={`${prefix}${device[deviceSettings.platform]}-${
                deviceSettings.orientation
              }.png`}
            />
          </svg>
        </div>
      </div>

      {link && <TLink to={link} className={styles.link} params={{ from }} />}
    </div>
  );
};

export { CaseCell };
